import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { Form } from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs'
import { useState } from 'react'
import useLeadServices from '../../../services/leadServices'

const LeadUplodForm = (props) => {
    const { handleBulkSubmit, handleUploadClose, imagePreview, handleFile, previewText } = props
    const [csvData, setCSVData] = useState("")

    useEffect(() => {
        getTemplate();
    }, [])

    const { getExcelFieldTypes } = useLeadServices()
    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDrop(event) {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        //console.log(event)
        handleFile(event);
    }

    const getTemplate = async()=>{
        const response = await getExcelFieldTypes()
        setCSVData(response?.data)
    }

    return (
        <div className='mx-3'>
            <Form onSubmit={handleBulkSubmit}>
                <div className="row my-2">
                    <div className="col h4 fw-bolder">New Upload</div>
                    <div className="btn col-md-2 btn-success btn-pill float-end lead-template">
                        <CSVLink data={csvData} filename={'lead_upload_template.csv'}>
                            <BsDownload size="15" strokeWidth={1}/> Template
                        </CSVLink>
                    </div>
                </div>
                <div className="row my-2">
                    <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                        <div className="mb-3">
                            <img className='uploadPreview' src={imagePreview} alt="" />
                        </div>
                        {previewText}
                        <div className="mt-3">
                            <div>
                                <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                <input id='leadImage' onChange={(e) => handleFile(e)} hidden className='form-control' type="file" accept='.csv' />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="px-0    ">
                        <div onClick={handleUploadClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                        <button onClick={handleBulkSubmit} className='btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-md-3'>Submit</button>
                    </div>
                </div>
            </Form >
        </div >
    )
}

export default LeadUplodForm