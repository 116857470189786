import React from 'react'
import { useEffect, useState } from 'react'

import "./FollowUps.css"

import FollowUpCard from './components/FollowUpCard'
import useCompanyServices from '../../services/companyServices'
import useLeadServices from '../../services/leadServices'
import useAuth from '../../hooks/useAuth'
import { Dropdown } from 'semantic-ui-react'

const FollowUps = () => {
  const [followupList, setFollowUpList] = useState()
  const [fieldTypeList, setFieldTypeList] = useState("")

  const { auth } = useAuth()

  const { getFollowUpList} = useCompanyServices()
  const { getStaffsFollowUpList, getActiveFieldTypes } = useLeadServices()

  useEffect(() => {
    getFollowUpListData()
    getFieldTypeList()
  }, [])

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes()
    if (response !== null) {
        setFieldTypeList(response.data)
    }
  }

  const getFollowUpListData = async (temp) => {
    var response = null
    if (auth?.role === 'company'){
      response = await getFollowUpList(temp)
    }
    else{
      response = await getStaffsFollowUpList(temp)
    }
    if (response !== null) {
      //console.log(response?.data)
      setFollowUpList(response.data.leads)
    }
  }

  const handlePriority = (temp) => {
    getFollowUpListData(temp)
  }

  // const handleSearch = () => {}
  return (
    <div className="">
      <div className="row pe-1">
        <div className="col-md-2 my-3 ps-md-0 ps-3">
          <Dropdown
            className='btn btn-sm btn-primary'
            text="Priority"
            >
            <Dropdown.Menu className='mt-2 p-3'>
              <Dropdown.Item onClick={() => handlePriority("Hot")}>Hot</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePriority("Warm")}>Warm</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePriority("Cold")}>Cold</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row mt-3 mb-3 me-0">
        <div className="col-lg-12">
          {window.innerWidth>768&&<div className="followup-title-card col-12 row p-3 pe-5">
            <div className="col-1 ps-2 pe-0 p-2 followup-card-head serial-number">Sl.<br />no</div>
            <div className="col-1 p-2 followup-card-head px-0">Name</div>
            <div className="col-2 followup-card-head p-2 pe-0 ps-3">Phone number</div>
            {/* <div className="col-1 followup-card-head p-0"></div> */}
            <div className="col-3 followup-card-head p-2 px-0">E-mail</div>
            <div className="col-1 followup-card-head p-2 ps-0 pe-5">Course</div>
            <div className="col-1 followup-card-head p-2 ps-0 pe-5">Location</div>
            <div className="col-1 followup-card-head p-2 ps-1 pe-0">Priority</div>
            <div className="col-1 followup-card-head p-2 ps-0 pe-0">Follow Up</div>
            <div className="col-1 followup-card-head p-2 ps-1 pe-0">Status</div>
            {/* <div className="col-1 followup-card-head p-2 pe-2 text-center">Desc</div> */}
          </div>}
        </div>
      </div>
      <div className="followup-container">
        {followupList && followupList.map(
          (data, index) => {
            return (
              <div key={index} className="row followup-data me-0">
                <FollowUpCard auth={auth} index={index + 1} data={data} updateFollowUps={()=>getFollowUpListData()} getFieldTypeList={getFieldTypeList} fieldTypeList={fieldTypeList} />
              </div>
            )
          }
        )
        }
      </div>
    </div>
  )
}

export default FollowUps