
import "./Dashboard.css"
// import user from "../../assets/images/logos/user.png"
// import user_success from "../../assets/images/logos/user_success.png"
// import graph from "../../assets/images/logos/graph.png"
// import graph_success from "../../assets/images/logos/graph_success.png"
import upload_img from "../../assets/images/icons/upload_img.png"
import check from "../../assets/images/icons/check.png"


// import { Icon } from 'semantic-ui-react';
// import DashboardCard from "./components/DashboardCard"
// import { Line } from "react-chartjs-2"
// import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
// import LeadUplodForm from "./components/LeadUplodForm"
import useAuth from "../../hooks/useAuth"
import DashboardCompany from "./components/DashboardCompany"
import DashboardAgent from "./components/DashboardAgent"
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import useStaffServices from '../../services/staffServices'
import useLeadServices from '../../services/leadServices'
import useBaseServices from "../../services/baseServices"
import { MEDIA_URL } from "../../api/axios"
import Swal from "sweetalert2"


const Dashboard = () => {
  const [cardData, setCardData] = useState()
  const [weeks, setWeeks] = useState()
  const [dayOpen, setDayOpen] = useState()
  const [dayClose, setDayClose] = useState()
  const [months, setMonths] = useState()
  const [monthData, setMonthData] = useState()
  // const [source, setSource] = useState()
  const [status, setStatus] = useState()
  const [show, setShow] = useState(true)

  const { auth,setAuth } = useAuth()

  const { getStaffProfile } = useBaseServices()
  const { getCompanyDashboard, getCompanyProfile } = useCompanyServices()
  const { getAgentDashboard } = useAgentServices()
  const { getStaffDashboard } = useStaffServices()


  useEffect(() => {
    getDashboard()
    getProfile()
  }, [])

  const getProfile = async () => {
    var image;
    if(auth.role === 'company'){
      const response = await getCompanyProfile()
      image = response.data.logo
    }
    else{
      const response = await getStaffProfile()
      image = response.data.image
    }
    // console.log(image)
    localStorage.setItem("image", MEDIA_URL+image)
    setAuth({ image})

  }

  const getDashboard = async () => {
    var response = null
    if(auth.role === 'company'){
      response = await getCompanyDashboard()
    }
    else if(auth.role === 'agent'){
      response = await getAgentDashboard()
    }
    else{
      response = await getStaffDashboard()
    }
    //console.log(response.data.month['months'])
    // var weekday = response.data.week
    setCardData(response.data.card)
    setWeeks(response.data.week['days'])
    setDayOpen(response.data.week['daily_leads'])
    setDayClose(response.data.week['daily_closed'])
    setMonths(response.data.month['months'])
    setMonthData(response.data.month['monthly_percent'])
    setStatus(response.data.status)
    // response.data.source?setSource(response.data.source):setSource('')  
    
  }
  //charts

  // const createGradient = () => {
  //   const gradient = createLinearGradient('to top', rgba(140, 39, 165,1),rgba(140, 39, 165,0.5))
  // }

  //line charts
  const lineOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        backgroundColor: '#3E66CD',
        yAlign: 'bottom',
        boxWidth: 20,
        padding: 10,
        bodySpacing: 10
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawTicks: false, //hide datatype lines
        },
        beginAtZero: true,
        ticks: {
          display: false //hide the datatype values
        }
      },
    },
  };
  const lineLabels = weeks;
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        
        fill:true,
        label: 'Opened',
        data: dayOpen,
        borderColor: '#8C27A5',
        backgroundColor: 'rgba(140, 39, 165,0.5)',
        tension: 0.3
      },
      {
        fill: true,
        label: 'Closed',
        data: dayClose,
        borderColor: 'rgb(69, 203, 233)',
        backgroundColor: 'rgba(69, 203, 233,0.5)',
        tension: 0.3
      },
    ],
  };

  //bar chart
  var dat1 = show?(monthData&&monthData.slice(6, ))||"":(monthData&&monthData.slice(0, 6))||""
  var dat2 = dat1&&dat1.map(data => 100 - data)
  const barData = {
    labels: show?months&&months.slice(6, ):months&&months.slice(0 ,6),
    datasets: [
      {
        label: 'Closed',
        backgroundColor: '#FFB82F',
        data: dat1,
        borderRadius: {
          topRight: 10,
          topLeft: 10,
          bottomRight: 10,
          bottomLeft: 10
        },
        borderWidth: 2,
        barPercentage: 0.26,
        categoryPercentage: 1.1,
        borderSkipped: false,
      },
      {
        label: 'New Leads',
        backgroundColor: '#275ADE',
        data: dat2,
        borderRadius: {
          topRight: 10,
          topLeft: 10,
          bottomRight: 10,
          bottomLeft: 10
        },
        barPercentage: 0.26,
        categoryPercentage: 1.1,
        borderWidth: 2,
        borderSkipped: false,
      },
    ],
  };

  const barOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        // display:false,
        grid: {
          display: true,
          drawTicks: false //hide datatype lines
        },
        beginAtZero: true,
        ticks: {
          display: false //hide the datatype values
        }
      },
    },
  };

  //pie chart
  const pieOptions = {
    plugins: {
      legend: {
        display: false
      },tooltip: {
        // backgroundColor: '#fff',
        yAlign: {
          display: false,
        },
      }
    }
  }
  const pieData = {
    labels: ["New Lead", "Open", "Rejected", "Closed", "Not Responded", "No Leads"],
    datasets: [{
      data: status&&[status['new'], status['open'], status['rejected'], status['closed'], status['unavailable'],status['new']==0?1:0],
      backgroundColor: [
        'rgba(123, 6, 194,0.5)', 'rgba(0, 55, 195, 0.5)', '#CA380A', 'rgba(188, 0, 124, 0.5)', 'rgba(255, 199, 0, 0.5)','rgba(244, 244, 244, 0.5)'
      ]
    }]

  }


  return (
    auth.role === "company" || auth.role==="agent" ?
      <DashboardCompany
        {...{ lineData, lineOptions, barData, barOptions, pieData, pieOptions, cardData, show, setShow }}
      />
      :
      <DashboardAgent
        {...{ lineData, lineOptions, barData, barOptions, pieData, pieOptions, cardData, show, setShow }}
      />
  )
}

export default Dashboard