import React from 'react'
import { Form } from 'react-bootstrap'

function NewFieldForms({data,datas}) {
    const handleData = (e) =>{
        e.preventDefault()
        if (data.type==="File"||data.type==="Image"){
            datas.delete(data?.name)
            datas.append(data?.name, e.target.files[0])
        }
        else{
            datas.delete(data?.name)
            datas.append(data?.name, e.target.value)
        }
    }
  return (
    <Form.Group className='' controlId='leadName'>
        <Form.Label className='fw-bolder'>{data?.name}</Form.Label>
        {data.type==="Character"&&
        <Form.Control
            type="text"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Number"&&
        <Form.Control
            type="number"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Email"&&
        <Form.Control
            type="email"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="TextArea"&&
        <Form.Control
            as='textarea'
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="File"&&
        <Form.Control
            type="file"
            className='py-2'
            onChange={(e)=>handleData(e)}
        />}
        {data.type==="Image"&&
        <Form.Control
            type="file"
            className='py-2'
            accept='image/*'
            onChange={(e)=>handleData(e)}
        />}
    </Form.Group>
  )
}

export default NewFieldForms
