import "./Profile.css"

import add_img from "../../assets/images/icons/add_img.png"
import upload_img from "../../assets/images/icons/upload_img.png"
import check from "../../assets/images/icons/check.png"
import { Icon } from "semantic-ui-react"
import { Form, Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import useAuth from "../../hooks/useAuth"
import useCompanyServices from "../../services/companyServices"
import useBaseServices from "../../services/baseServices"
import Swal from 'sweetalert2'

import { MEDIA_URL } from "../../api/axios"
import ExtensionForm from "../../components/forms/ExtensionForm"


const Profile = () => {
    const [profile, setProfile] = useState("")
    const [personalEdit, setPersonalEdit] = useState(false)
    const [showImageEdit, setShowImageEdit] = useState(false)
    const [name, setName] = useState("")
    const [imageName, setImageName] = useState("")
    const [imageData, setImageData] = useState("")
    const [position, setPosition] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [user, setUser] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [numberModal, setNumberModal] = useState(false)

    const handleNumberClose = ()=>{
        setNumberModal(false)
    }

    const { auth } = useAuth()

    const { getCompanyProfile, putCompanyProfile } = useCompanyServices()
    const { getStaffProfile, putStaffProfile, putPasswordChange } = useBaseServices()

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        try {
            var response;
            if (auth.role === "company") {
                response = await getCompanyProfile()
                setName(response.data.company_name)
                setPosition('Admin')
                setProfile(MEDIA_URL + response.data.logo)
                setUser(response?.data?.fkAdmin)
            } else {
                response = await getStaffProfile()
                setName(response.data.first_name)
                setPosition(response.data.post)
                setProfile(MEDIA_URL + response.data.image)
                setUser(response?.data?.fkUser)
            }
            if (response.success) {
                // setProfile(response.data)
                setEmail(response.data.email)
                setPhone(response.data.mobile)
            }
        } catch (err) {

        }
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault()
        try {
            if (password1 === password2) {
                const data = new FormData();
                data.append("new_password", password1)
                const response = await putPasswordChange(data)
                // console.log(response)
                if (response.success) {
                    Swal.fire("", "Password changed successfully !!!", "success")
                    setPassword1("")
                    setPassword2("")
                    getProfile()
                }
            }
            else {
                Swal.fire("", "Passwords are not equal !!!", "error")
            }

        } catch (err) {

        }
    }

    const handlePersonalSubmit = async (e) => {
        e.preventDefault()
        try {
            const data = new FormData();
            if (auth.role === "company") {
                data.append("company_name", name)

            } else {
                data.append("first_name", name)
                data.append("post", position)
            }
            data.append("mobile", phone)
            data.append("email", email)

            var response;
            if (auth.role === "company") {
                response = await putCompanyProfile(data)
            }
            else {
                response = await putStaffProfile(data)
            }
            // console.log(response)
            if (response.success) {
                Swal.fire("", "Profile Edited successfully !!!", "success")
                getProfile()
                setPersonalEdit(!personalEdit)
            }

        } catch (err) {

        }
    }

    const handleImageSubmit = async (e) => {
        e.preventDefault()
        try {
            const data = new FormData();
            if (auth.role === "company") {
                data.append("logo", imageData)

            } else {
                data.append("image", imageData)
            }
            var response;
            if (auth.role === "company") {
                response = await putCompanyProfile(data)
            }
            else {
                response = await putStaffProfile(data)
            }
            // console.log(response)
            if (response.success) {
                Swal.fire("", "Image Updated successfully !!!", "success")
                getProfile()
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }

        } catch (err) {

        }
    }

    const handlePasswordCancel = () =>{
        setPassword1("")
        setPassword2("")
    }

    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDrop(event) {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    }

    const handleImage = (e) =>{
        if (!e.target.files || e.target.files.length === 0) {
          setImageData(undefined)
          return
        }
        setImageName(e.target.files[0].name)
        setImageData(e.target.files[0])
    }

    const handleClose = () =>{
        setImageData("")
        setImageName("")
        setShowImageEdit(false)
    }

    return (
        <div className='row me-3'>
            <div className="profile-card-container px-5 py-4">
                <div className="px-4">
                    <div className="row my-3">
                        <div className="h5 fw-bolder">My Profile</div>
                    </div>
                    <div className="my-3 profile-card row py-2 px-4">
                        <div className="col-md-2 col-12 text-center my-md-0 my-2">
                            <div className="">
                                <img src={profile || add_img} className="profile-user-img mouse-pointer" alt="" onClick={()=>setShowImageEdit(true)}/>
                            </div>
                        </div>
                        <div className="col-md-8 my-auto col-12 text-center text-md-start">
                            <div className="ms-2">
                                <div className="mb-2 profile-card-title">{name || ""}</div>
                                <div className="profile-card-post">{position || "Admin"}</div>
                                {/* <div className="profile-card-location">{Location}</div> */}
                            </div>
                        </div>
                        <div className="col-md-2 my-auto col-12 text-center text-md-end">
                            {/* <div className="ms-1">
                                <div className="btn btn-sm btn-dark fs-6" onClick={()=>setNumberModal(true)}>Caller ID</div>
                            </div> */}
                        </div>
                        {/* <div className="col-md-2 my-auto ">
                            <div className="ms-2">
                                <button className="btn btn-outline-primary btn-pill col-12">Edit <Icon name="edit" /></button>
                            </div>
                        </div> */}
                    </div>
                    <div className="my-3 profile-card row">
                        <Form onSubmit={handlePersonalSubmit}>
                            <div className="row py-3 px-4">
                                <div className="row mx-0 px-0">
                                    <div className="col-md-10">
                                        <div className="fw-bolder">
                                            Personal Details
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-auto ">
                                        <div className="ms-2">

                                            {/* required */}

                                            {personalEdit ?
                                                 window.innerWidth>768&&<button type="button" onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Cancel</button>
                                                :<span onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Edit <Icon name="edit" /></span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 px-0">
                                    <div className="col-md-5">
                                        <div className="mb-3">
                                            <label className="profile-card-label" htmlFor="">Name</label>
                                            <div className="">

                                                {/* Required */}

                                                {personalEdit ?
                                                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} />

                                                    : name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5"></div>
                                    <div className="col-md-2 my-auto">
                                        <div className="ms-2">

                                            {/* Required */}
                                            {personalEdit && window.innerWidth>768&&<button type="submit" className="btn btn-primary btn-pill col-12">Save</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="">
                                            <label className="profile-card-label" htmlFor="">Position</label>
                                            <div className="">

                                                {/* Required */}
                                                {
                                                    personalEdit ?
                                                        <Form.Control value={position} onChange={(e) => setPosition(e.target.value)} disabled />

                                                        :
                                                        position}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="">
                                            <label className="profile-card-label" htmlFor="">Email</label>
                                            <div className="">
                                                {/* Required */}
                                                {personalEdit ?
                                                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />

                                                    : email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="">
                                            <label className="profile-card-label" htmlFor="">Phone</label>
                                            <div className="">

                                                {/* Required */}
                                                {personalEdit ?
                                                    <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} />

                                                    : phone}
                                            </div>
                                        </div>
                                    </div>
                                    {window.innerWidth<=768&&<div className="col-md-2">
                                        <div className="ms-2 my-auto">
                                            <div className="mb-3">
                                            {personalEdit && <button type="submit" className="btn btn-primary btn-pill col-12">Save</button>}</div>
                                            <div className="">
                                            {personalEdit && <button type="button" onClick={(e) => setPersonalEdit(!personalEdit)} className="btn btn-outline-primary btn-pill col-12">Cancel</button>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="my-3 profile-card row  py-4 px-4">
                        <Form onSubmit={handlePasswordSubmit}>
                            <div className="row">
                                <div className="col-md-10 ">
                                    <div className="row mb-3 ">
                                        <div className="col-md-6"><Form.Label>New Password</Form.Label></div>
                                        <div className="col-md-6"><Form.Control type="password" value={password1} placeholder="New Password" onChange={(e) => setPassword1(e.target.value)} /></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6"><Form.Label>Confirm Password</Form.Label></div>
                                        <div className="col-md-6"><Form.Control type="password" value={password2} placeholder="Confirm password" onChange={(e) => setPassword2(e.target.value)} /></div>
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <div className="ms-2 my-auto">
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-warning btn-pill col-12 border-dark">Change</button>

                                        </div>
                                        <div className="">
                                            <div onClick={handlePasswordCancel} className="btn btn-outline-danger btn-pill col-12">Cancel</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                centered
                show={showImageEdit}
                onHide={handleClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleImageSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Update Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageName?
                                    <>
                                    <div className="mb-3">
                                        <img className='uploadPreview' src={check} alt="" />
                                    </div>
                                    <div className="">
                                        <div>{imageName}</div>
                                    </div>
                                    </>:
                                    <>
                                        <div className="mb-3">
                                            <img className='uploadPreview' src={upload_img} alt="" />
                                        </div>
                                        <div className="">
                                            <div>Drag & Drop Files Here</div>
                                            <div>or</div>
                                        </div>
                                    </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button onClick={handleImageSubmit} className='btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3'>Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                show={numberModal}
                onHide={handleNumberClose}
                centered={true}
                size="md"
                contentClassName=""
                >
                <Modal.Body>
                    <ExtensionForm setNumberModal={setNumberModal} userID={user} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Profile