import React from 'react'
import { useEffect, useState } from 'react'

import "./Lead.css"
import plus from "../../assets/images/icons/plus-circle-fill.png"
import upload_img from "../../assets/images/icons/upload_img.png"
import check from "../../assets/images/icons/check.png"

import {
  Dropdown as DropDownBootstrap, DropdownButton,
  Modal
} from 'react-bootstrap'
// import { Search } from 'semantic-ui-react';
import { Icon } from "semantic-ui-react"
import LeadCard from "./components/LeadCard"
import LeadAddForm from './components/LeadAddForm'
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import useStaffServices from '../../services/staffServices'
// import useCustomerServices from '../../services/customerServices'
import useAuth from '../../hooks/useAuth'
import { DatePicker } from '@mui/x-date-pickers'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import { Dropdown } from 'semantic-ui-react'
import useLeadServices from '../../services/leadServices'
import LeadUplodForm from '../dashboard/components/LeadUplodForm'

const Leads = () => {
  const [show, setShow] = useState(false)
  const [check, setCheck] = useState(false)
  const [leadList, setLeadList] = useState()
  const [fieldTypeList, setFieldTypeList] = useState("")
  const [uploadModal, setUploadModal] = useState(false)
  const [imagePreview, setImagePreview] = useState(upload_img)
  const [previewText, setPreviewText] = useState()
  const [selected, setSelected] = useState("")
  // const [date, setDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  const [filter, setFilter] = useState("Filter")

  const { auth } = useAuth()

  const { getLeadListToday } = useCompanyServices()
  const { getAssignedLeadList } = useAgentServices()
  const { getLeadList } = useStaffServices()
  const { getActiveFieldTypes, postLeadBulkUpload } = useLeadServices()

  useEffect(() => {
    getLeadListData();
    getFieldTypeList()
  }, [])

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes()
    if (response !== null) {
      // console.log(response?.data)
      setFieldTypeList(response.data)
    }
  }

  const getLeadListData = async (type, temp, multiple) => {
    var response = null
    if (auth?.role === 'company') {
      response = await getLeadListToday(
        // type, format(date, 'yyyy-MM-dd')
        type, temp, multiple
      )
    }
    else if (auth?.role === 'agent') {
      response = await getAssignedLeadList(type, temp, multiple)
    }
    else {
      response = await getLeadList(type, temp, multiple)
    }
    if (response?.success) {
      // console.log(response?.data?.leads)
      setLeadList(response?.data?.leads)
    }
  }

  // const handleSearch = () => {

  // }

  const handleDate = async (e) => {

    // setDate(e['$d'])
    getLeadListData("date", format(e['$d'], 'yyyy-MM-dd'))
    // console.log(e['$d'])

  }

  const handleDateRange = async (e) => {
    e.preventDefault()
    getLeadListData(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), true)
    // // setDate(date[0])
    // console.log(format(date, 'yyyy-MM-dd'))
    // console.log(format(fromDate, 'yyyy-MM-dd'))
    // console.log(format(toDate, 'yyyy-MM-dd'))
  }

  const handleClose = () => setShow(false)

  const handleStatus = (temp) => {
    setFilter(temp)
    getLeadListData("status", temp)
  }

  const handlePriority = (temp) => {
    getLeadListData("priority", temp)
  }

  const handleUploadOpen = () => setUploadModal(true);
  const handleUploadClose = () => {
    setSelected('')
    setUploadModal(false)
  }

  useEffect(() => {
    if (!selected) {
      setImagePreview(upload_img)
      setPreviewText(
        <div className="">
          <div>Drag & Drop Files Here</div>
          <div>or</div>
        </div>
      )
      return
    }

    const objectUrl = URL.createObjectURL(selected)
    setImagePreview(check)
    setPreviewText(
      <div className="">
        <div>{selected.name}</div>
      </div>
    )

    return () => URL.revokeObjectURL(objectUrl)
  }, [selected])

  const handleFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelected(undefined)
      return
    }
    //console.log(e.target.files[0])

    setSelected(e.target.files[0])
  }


  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData()
      data.append("file", selected)
      const response = await postLeadBulkUpload(data)
      if (response?.success) {
        Swal.fire("", response?.message, "success")
        getLeadListData()
        getFieldTypeList()
        handleUploadClose()
      }
      else {
        Swal.fire("", response?.message, "error")
      }
    } catch (err) {
      //console.log(err)
    }
  }

  const handleFilter = (data) => {
    setFilter(data)
    if (data == "Today") {
      getLeadListData("date", format(new Date(), 'yyyy-MM-dd'))


    }
    else if (data == "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      getLeadListData("date", format(date, 'yyyy-MM-dd'))


    }
  }
  return (
    <div className="">
      <div className="row pe-1">

        {/* <div id="leadsearch" className="row z-index-n1 pe-1"> */}
        {/* {auth?.role === "company" &&
            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
              <div className="me-0">
              <Search
                input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                className="lead-searchBox"
                placeholder="Search Here..."
                size='large'
                fluid={true}

                // onResultSelect={}
                onSearchChange={handleSearch}
              />
              </div>
            </div>} */}
        <div className="col-md-1 col-3 my-3 ms-md-1 ms-3 pe-0">
          <Dropdown
            className='btn btn-sm btn-primary'
            text="Status"

          >
            <Dropdown.Menu className='mt-2 p-3'>
              <Dropdown.Item onClick={() => handleStatus("New")}>New</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatus("Open")}>Open</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatus("Unavailable")}>Unavailable</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatus("Closed")}>Closed</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatus("Rejected")}>Rejected</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatus("Paused")}>Paused</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-1 col-3 my-3 ms-1 pe-0">
          <Dropdown
            className='btn btn-sm btn-primary'
            text="Priority"
          >
            <Dropdown.Menu className='mt-2 p-3'>
              <Dropdown.Item onClick={() => handlePriority("Hot")}>Hot</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePriority("Warm")}>Warm</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePriority("Cold")}>Cold</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="filter-container col-4  ms-1 pe-0 col-md-2 my-3">
          <Dropdown id="dropdown-basic-button"
            className='btn btn-sm btn-primary'
            text={filter}>
            <Dropdown.Menu  className='mt-2 p-3'>
              <Dropdown.Item onClick={() => handleFilter("Today")}>Today</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilter("Yesterday")}>Yesterday</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilter("Specific day")}>Specific day</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilter("Date Range")}>Date Range</Dropdown.Item>
            </Dropdown.Menu></Dropdown>
        </div>
        {filter === "Specific day" &&
          <>
            <div className="col-md-2 my-3 ps-1">
              <DatePicker
                className='lead-datepicker'
                onChange={(e) => handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          </>}
        {filter === "Date Range" &&
          <>
            <div className="col-md-2 my-3 ps-1">
              <DatePicker
                className='lead-datepicker col-12'
                onChange={(e) => setFromDate(e['$d'])}
                format="DD-MMM-YYYY"
              />
            </div>
            <div className="col-md-3 my-3 ps-1">
              <DatePicker
                className='lead-datepicker col-8 pe-2'
                onChange={(e) => setToDate(e['$d'])}
                format="DD-MMM-YYYY"
              />
              <div className='btn btn-pill btn-outline-primary col-4' onClick={(e) => handleDateRange(e)}>search</div>
            </div>

          </>
        }
        {/* </div> */}
        <div id="" className="col-md col-12 my-3 me-3 text-md-end text-center">
          <div onClick={handleUploadOpen} id="addLead" className="ui circular button text-bg-primary">
            <Icon name="cloud upload" />
            Lead Bulk Upload
          </div>
          <Modal
            show={uploadModal}
            onHide={handleUploadClose}
            centered
            size="lg"
            contentClassName="upload-modal"
          >
            <Modal.Body className="p-4">
              <LeadUplodForm {...{ handleFile, imagePreview, handleBulkSubmit, handleUploadClose, previewText }} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <div className="row mt-3 ms-1 mb-3 me-0">
        <div className="col-lg-12">
          {window.innerWidth > 768 && <div className="lead-title-card col-12 row p-3 pe-4 me-0">
            <div className="col-1 ps-2 pe-0 p-2 lead-card-head serial-number">Sl.<br />no</div>
            <div className="col-1 p-2 lead-card-head px-0">Name</div>
            <div className="col-2 lead-card-head p-2 ps-1">Phone number</div>
            <div className="col-3 lead-card-head p-2 px-0">E-mail</div>
            <div className="col-1 lead-card-head p-2 px-0">Created</div>
            <div className="col-1 lead-card-head p-2 ps-2">Source</div>
            <div className="col-1 lead-card-head p-2 px-0">Interested</div>
            <div className="col-1 lead-card-head p-2 ps-2">Location</div>
            <div className="col-1 lead-card-head p-2 ps-1  pe-5">Status</div>
            {/* <div className="col-1 lead-card-head p-2 pe-4 text-center">Desc</div> */}
          </div>}
        </div>
      </div>
      <div className="row lead-container">
        {leadList && leadList.map(
          (data, index) => {
            return (
              <div key={index} className="row lead-data ms-1 me-2 ">
                <LeadCard auth={auth} index={index + 1} data={data} updateLeads={getLeadListData} getFieldTypeList={getFieldTypeList} fieldTypeList={fieldTypeList} />
              </div>
            )
          }
        )
        }
      </div>
      {/* <div className='row'>
        <div className='col-md-5'>
          
        </div>
        <div className='col-md-1 p-3' hidden>
          <i className='btn btn-outline-primary'><Icon name='arrow left' size='big' /></i>
        </div>
        <div className='col-md p-3'>
          <span className='btn btn-primary'><span className='next-page'>Next </span><Icon name='arrow right' size='big' className='' /></span>
        </div>
      </div> */}
      {/* {auth.role === "company" && */}
      <div className=" ">
        <div onClick={() => setShow(true)} className="button position-fixed bottom-0 end-0">
          <img src={plus} className=" lead-plus-button" alt="" />

          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className='p-5'>
              <LeadAddForm
                handleClose={handleClose}
                refresh={getLeadListData}
              />
            </Modal.Body>
          </Modal>
        </div>
      {/* } */}
    </div>
  )
}

export default Leads