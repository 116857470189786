import { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { Checkbox } from "semantic-ui-react"
import Swal from "sweetalert2"
import useCustomerServices from "../../../services/customerServices"
import useLeadServices from "../../../services/leadServices"
import DeleteForm from "./DeleteForm"


function TableData({data, refresh, type, index}) {
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [checked, setChecked] = useState("")
    const [name, setName] = useState(data?.name)
    const [category, setCategory] = useState(data?.type||"")
    useEffect(()=>{
        setChecked(data?.active)
    }, [data])

    const { putInterestDetails, putDocTypeDetails, putStatusDetails } = useCustomerServices()
    const { putFieldTypeDetails, putFieldTypeStatus } = useLeadServices()

    const handleCheck = async() => {
        try {
            var response;
            if (type === 'Interest'){
                response = await putInterestDetails(data.id, null)
            }
            else if (type === 'DocType'){
                response = await putDocTypeDetails(data.id, null)
            }
            else if (type === 'Status'){
                response = await putStatusDetails(data.id, null)
            }
            else if (type === 'Field Type'){
                response = await putFieldTypeStatus(data.id, null)
            }
            
            if (response.success) {
                Swal.fire("", "Activation Changed Successfully", "success")
                refresh()
                setChecked(!checked)                
            } else {
                Swal.fire("", "Some Errors occured", "error")
            }
        } catch (err) {
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            var response;
            var datas;
            type!=="Field Type"?
            datas = JSON.stringify(
                {
                    name:name,
                }
            ):
            datas = JSON.stringify(
                {
                    name:name,
                    type:category,
                }
            );
            if (type === 'Interest'){
                response = await putInterestDetails(data.id, datas)
            }
            else if (type === 'DocType'){
                response = await putDocTypeDetails(data.id, datas)
            }
            else if (type === 'Status'){
                response = await putStatusDetails(data.id, datas)
            }
            else if (type === 'Field Type'){
                response = await putFieldTypeDetails(data.id, datas)
            }
            
            if (response.success) {
                Swal.fire("", "Data Edited Successfully", "success")
                handleEditClose()
                refresh()
            } else {
                Swal.fire("", "Some Errors occured", "error")
            }
        } catch (err) {
        }
    }

    const handleClose = () => setShowDelete(false)
    const handleEditClose = () => setShowEdit(false)
  return (
    <>
    <div className='row border-md-0 border'>
        {window.innerWidth>768&&<div className="col-1 ps-md-5 my-1 p-1">{index+1}</div>}
        {type !== 'Field Type'
        ?
            <>
                {window.innerWidth>768&&<div className="col-8 my-1 ps-4 p-1" >{data?.name}</div>}
                {window.innerWidth<768&&<div className="col-12 my-1 ps-4 p-1" ><b>Name :</b> {data?.name}</div>}
            </>
        :
            <>
                {window.innerWidth>768&&<div className="col-5 my-1 ps-4 p-1" >{data?.name}</div>}
                {window.innerWidth<768&&<div className="col-6 my-1 ps-4 p-1" ><b>Name :</b> {data?.name}</div>}
                {window.innerWidth>768&&<div className="col-3 my-1 ps-2 p-1" >{data?.type}</div>}
                {window.innerWidth<768&&<div className="col-6 my-1 ps-2 p-1" ><b>Type :</b> {data?.type}</div>}
            </>
        }
        <div className="col-md-1 ps-4 ps-md-1 col-4">
            {window.innerWidth>768&&<button onClick={()=>setShowEdit(true)} className='btn my-1 p-1 btn-primary '><BiEditAlt size="20px" /></button>}
            {window.innerWidth<768&&<button onClick={()=>setShowEdit(true)} className='btn my-1 p-1 px-2 btn-primary '><BiEditAlt size="20px" /> Edit</button>}
        </div>
        <div className="col-md-1 col-4 ps-md-1 pt-2">
            <Checkbox
            toggle
            checked={checked}
            className=""
            onChange={()=>handleCheck()}
            />
        </div>
        <div className="col-md-1 col-4 ps-md-3 ps-0">
            {window.innerWidth>768&&<button onClick={()=>setShowDelete(true)} className='btn my-1 p-1 btn-outline-danger '><MdDelete size="20px" /></button>}
            {window.innerWidth<768&&<button onClick={()=>setShowDelete(true)} className='btn my-1 p-1 px-2 btn-outline-danger '><MdDelete size="20px" /> Delete</button>}
        </div>
    </div>
    <Modal
        show={showDelete}
        onHide={handleClose}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className='p-5'>
            <DeleteForm data={data} refresh={refresh} type={type} handleClose={handleClose} />
        </Modal.Body>
    </Modal>
    <Modal
        show={showEdit}
        onHide={handleEditClose}
        centered={true}
        size="md"
        contentClassName=""
    >
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
                <div className='p-2'>
                    {type==="Interest"?<h3>Edit Product</h3>:<h3>Edit {type}</h3>}
                    {type!=="Field Type"
                    ?
                        <div className=" my-3">
                            <Form.Group className='' controlId='staffName'>
                                <Form.Label className=''>{type}</Form.Label>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} className='py-2' placeholder="Eg: visa" />
                            </Form.Group>
                        </div>
                    :
                        <div className="row my-3">
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='staffName'>
                                    <Form.Label className=''>Name</Form.Label>
                                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} className='py-2' placeholder="Eg: Gender" />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='staffName'>
                                    <Form.Label className=''>Type</Form.Label>
                                    <Form.Select
                                        className={"type-dropdown mouse-pointer "}
                                        size="md"
                                        value={category}
                                        onChange={(e)=>setCategory(e.target.value)}
                                        >
                                        <option className="menu" value="Character">Character</option>
                                        <option className="menu" value="Number">Number</option>
                                        <option className="menu" value="Email">Email</option>
                                        <option className="menu" value="TextArea">TextArea</option>
                                        <option className="menu" value="File">File</option>
                                        <option className="menu" value="Image">Image</option>          
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    }
                    <div className="position-relative mb-5">
                        <div className='position-absolute end-0 row'>
                            <div onClick={handleEditClose} className='col btn btn-danger mx-2'>Cancel</div>
                            <button className='col btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default TableData
