import React, { useEffect, useState } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import ContextAwareToggle from '../../../components/accordian/ContextAwareToggle'
import NewFieldForms from '../../../components/forms/NewFieldForms'
import useCompanyServices from '../../../services/companyServices'
import useLeadServices from '../../../services/leadServices'

const LeadAddForm = (props) => {
    const {handleClose, refresh} = props
    const [fieldTypeList, setFieldTypeList] = useState()
    const [leadId, setLeadId] = useState('')
    const [leadName, setLeadName] = useState('')
    const [leadEmail, setLeadEmail] = useState('')
    const [leadMobile, setLeadMobile] = useState('')
    const [leadLocation, setLeadLocation] = useState('')
    const [leadSource, setLeadSource] = useState('')
    const [leadInterest, setLeadInterest] = useState('')

    const datas = new FormData()

    const { getActiveFieldTypes, postNewLeadFieldContent } = useLeadServices()
    const { postLeadListToday } = useCompanyServices()

    useEffect(()=>{
        getFieldTypeList()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        try {
            const data = JSON.stringify({
                name: leadName,
                email: leadEmail,
                mobile: leadMobile,
                location: leadLocation,
                source: leadSource,
                interest: leadInterest
            });
            const response = await postLeadListToday(data)
            refresh()
            if (response?.success) {
                if (fieldTypeList){
                    setLeadId(response?.data?.id)
                }
                else{
                    Swal.fire("", response?.message, "success")
                    handleClose()
                } 
            }
            else{
                Swal.fire("", response?.message, "error")
                handleClose()
            }
        }
        catch (err) {
        // console.log(err)
        }
    }

    const getFieldTypeList = async() => {
        try{
            const response = await getActiveFieldTypes()
            // console.log(response?.data)
            if(response?.success){
                setFieldTypeList(response?.data)
            }
        }catch{}
    }

    const handleFieldSubmit = async(e) =>{
        e.preventDefault()
        try{
            const response = await postNewLeadFieldContent(leadId, datas)
            console.log(response?.data)
            if (response?.success){
                Swal.fire("Success", response?.message, "success")
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }
        }catch{}
        // console.log(datas, leadId)
    }

    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Add Lead</div>
            </div>
            <Accordion defaultActiveKey="0">
                <Accordion.Collapse eventKey='0'>
                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    <div className="col-md-8 px-1">
                                        <Form.Group className='' controlId='leadName'>
                                            <Form.Label className='fw-bolder'>Name</Form.Label>
                                            <Form.Control className='py-2' placeholder="Beserac" onChange={(e)=>setLeadName(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4 px-1">
                                        <Form.Group className='' controlId='leadMobile'>
                                            <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                            <Form.Control className='py-2' placeholder="1234567890" onChange={(e)=>setLeadMobile(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-5 px-1">
                                        <Form.Group className='' controlId='leadSource'>
                                            <Form.Label className='fw-bolder'>Source</Form.Label>
                                            <Form.Control className='py-2' placeholder="Direct Enquiry" onChange={(e)=>setLeadSource(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-7 px-1">
                                        <Form.Group className='' controlId='leadMail'>
                                            <Form.Label className='fw-bolder'>Email</Form.Label>
                                            <Form.Control className='py-2' placeholder="user@example.com" onChange={(e)=>setLeadEmail(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-7 px-1">
                                        <Form.Group className='' controlId='leadLocation'>
                                            <Form.Label className='fw-bolder'>Location</Form.Label>
                                            <Form.Control className='py-2' placeholder="landmark" onChange={(e)=>setLeadLocation(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-5 ">
                                        <Form.Group className='' controlId='leadCourse'>
                                            <Form.Label className='fw-bolder'>Course</Form.Label>
                                            <Form.Control className='py-2' placeholder="bescrsac" onChange={(e)=>setLeadInterest(e.target.value)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container">
                            <div className="row col-md-6">
                                <div className="col-md-6">
                                    <button type="button" onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-md-6">
                                    {fieldTypeList?
                                    <ContextAwareToggle eventKey='1'>
                                            <button type='submit' className='btn btn-primary staff-btn col-12'>Next</button>
                                    </ContextAwareToggle>:
                                        <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </Form>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1">
                    <Form onSubmit={handleFieldSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    {fieldTypeList&&fieldTypeList.map(
                                        (data,index)=>{
                                            return(
                                                <div className="col-md-6 px-1 pb-2">
                                                    <NewFieldForms data={data} key={index} datas={datas} />
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container">
                            <div className="row col-md-6">
                                <div className="col-md-6">
                                    <button type="button" onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-md-6">
                                    <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                                </div>
                            </div>
                            
                        </div>
                    </Form>
                </Accordion.Collapse>
            </Accordion>
            
        </div>
    )
}

export default LeadAddForm