import React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import useCustomerServices from '../../../services/customerServices'
import Swal from 'sweetalert2'

const CustomerAddForm = (props) => {
    const {handleClose, refresh, data} = props

    const [customerName, setCustomerName] = useState(data?.name||"")
    const [customerEmail, setCustomerEmail] = useState(data?.email||"")
    const [customerMobile, setCustomerMobile] = useState(data?.mobile||"")
    const [customerMobile2, setCustomerMobile2] = useState(data?.alt_mobile||"")

    const { postNewCustomer, putCustomerDetails } = useCustomerServices()

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        try {
          
          var response;
          if (!data){
            const datas = JSON.stringify({
            name: customerName,
            email: customerEmail,
            mobile: customerMobile,
            mobile2: customerMobile2,
          });
            response = await postNewCustomer(datas)
          }
          else{
            const datas = JSON.stringify({
                name: customerName,
                email: customerEmail,
                mobile: customerMobile,
                alt_mobile: customerMobile2,
            });
            response = await putCustomerDetails(data?.id, datas)
          }
        //   console.log(response?.data)
          if (response.success) {
            refresh()
            Swal.fire("", response?.message, "success")
            handleClose()
          }
        }
        catch (err) {
          //console.log(err)
        }
      }

    return (
        <div className="">
            <div className="row">
                {data?<div className="h3 fw-bolder">Edit Customer</div>:
                <div className="h3 fw-bolder">Add Customer</div>}
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row my-4">
                            <div className="col-md-8 px-1">
                                <Form.Group className='' controlId='leadName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control className='py-2' value={customerName} placeholder="Beserac" onChange={(e)=>setCustomerName(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 px-1">
                                <Form.Group className='' controlId='leadMobile'>
                                    <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                    <Form.Control className='py-2' value={customerMobile} placeholder="1234567890" onChange={(e)=>setCustomerMobile(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='leadSource'>
                                    <Form.Label className='fw-bolder'>Alternate Mobile</Form.Label>
                                    <Form.Control className='py-2' value={customerMobile2} placeholder="Direct Enquiry" onChange={(e)=>setCustomerMobile2(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='leadMail'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control className='py-2' value={customerEmail} placeholder="user@example.com" onChange={(e)=>setCustomerEmail(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="col-md-6"></div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className="col-md-6 pb-2">
                                <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                            </div>
                            <div className="col-md-6">
                                <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default CustomerAddForm