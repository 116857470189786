import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useStaffServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get dashboard data
    const getStaffDashboard = async () => {
        const response = await axiosPrivate.get("/staff/dashboard/")
        return response.data
    }

    //get lead list
    const getLeadList = async (type, data, multiple) => {
        if (multiple) {
            // here type = fromdate and data = todate
            const response = await axiosPrivate.get("/staff/lead_list/", {
                params: {
                    'from': type,
                    'to': data
                }
            })
            return response.data
        }
        else {
            // here type= parameter key and data = value
            const response = await axiosPrivate.get("/staff/lead_list/", {
                params: { [type]: data }
            })
            return response.data
        }
    }

    //get dashboard lead list
    const getDashboardLeadList = async () => {
        const response = await axiosPrivate.get("/staff/dashboard_lead_list/")
        return response.data
    }

    //returns
    return {
        getStaffDashboard,
        getLeadList,
        getDashboardLeadList
    }
}
export default useStaffServices