import './Settings.css'
import { Navigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Modal, Nav, Tab } from 'react-bootstrap'
import { useState } from 'react'
import StatusSettings from './components/StatusSettings'
import DocumentSettings from './components/DocumentSettings'
import InterestSettings from './components/InterestSettings'
import FieldSettings from './components/FieldSettings'
import CallSettings from './components/CallSettings'

const Settings = () => {
    const [createModal, setCreateModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)

    const { auth } = useAuth()


    const handleCreateOpen = () => setCreateModal(true);
    const handleViewOpen = () => setViewModal(true);

    const handleCreateClose = () => setCreateModal(false);
    const [key, setKey] = useState("tab1");

    const handleViewClose = () => setViewModal(false);
    return (
        auth.role === "company" ?
            <div className='ms-3 ms-md-0'>
                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                    <div className="row  me-2">
                        <div className="col-md-3">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab1">Customer Status Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab2">Document Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab3">Product Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab4">Field Settings</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab5">Call Settings</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </div>
                        <div className="col-md-9 settings-card p-3">
                            <Tab.Content>
                                <Tab.Pane eventKey="tab1">
                                    <div>
                                        <StatusSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    <div>
                                        <DocumentSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab3">
                                    <div>
                                        <InterestSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab4">
                                    <div>
                                        <FieldSettings/>
                                    </div>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="tab5">
                                    <div>
                                        <CallSettings/>
                                    </div>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            :
            <Navigate to="/" />
    )
}

export default Settings

