import React, { useState } from 'react'
import { useEffect } from 'react'
import useLeadServices from '../../services/leadServices'

const Notification = () => {
    const [notificationList, setNotificationList] = useState()
    const { getNewFollowUpReminder } = useLeadServices()

    useEffect(()=>{
        getNotificationList()
    }, [])

    const getNotificationList = async() =>{
        try{
            const response = await getNewFollowUpReminder()
            // console.log(response?.data)
            if (response?.success){
                setNotificationList(response?.data)
            }
        }catch{}
    }
  return (
    <div className='card col-12'>
        <div className='card-header col-12'>Follow Up Reminder</div>
        {/* <div className='card-body'> */}
            <ul class="list-group list-group-flush px-2 py-2">
                {notificationList&&notificationList.map(
                    (data, index)=>{
                        return (
                            <li className='card col-12 my-0'>
                                <div className='card-header'>
                                    {data.lead_name}
                                </div>
                                <div className='ps-4 p-2'>
                                    {data.note}
                                </div>
                            </li>
                        )
                    }
                )}
            </ul>
        {/* </div> */}
    </div>
  )
}

export default Notification