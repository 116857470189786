import { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import { Modal } from 'react-bootstrap'
import { MEDIA_URL } from "../../../api/axios"
import useAuth from "../../../hooks/useAuth"
import useCompanyServices from "../../../services/companyServices"
import ExtensionForm from "../../../components/forms/ExtensionForm"

const StaffCard = ({ img, user, role, data, open, edit, refresh }) => {
    const { auth } = useAuth()
    const [numberModal, setNumberModal] = useState(false)

    const { deleteCompanyStaffDetails } = useCompanyServices()

    const [showModal, setShowModal] = useState(false)
    const [staffData, setStaffData] = useState("")
    useEffect(() => {
        if (auth.role === "company") {
            setStaffData(data.report)
        } else if (auth.role === "agent") {
            setStaffData(data.fkStaff.report)
        }
    }, [])

    const handleClose = () => setShowModal(false)

    const handleSubmit = async (e) => {
        try {
            const response = await deleteCompanyStaffDetails(auth.role === "company" ? data.id : "")
            if (response?.success) {
                refresh()
            }
        } catch (err) {

        }
    }

    const handleNumberClose = ()=>{
        setNumberModal(false)
    }

    return (
        <div className="col-lg-4 col-md-6 my-3">
            {window.innerWidth > 768 || auth.role === "company" ? <div className="staff-card col-12 row py-1">
                <div className="col-5 staff-card-img my-2">
                    <img src={img && MEDIA_URL + img} className="" alt="" />
                </div>
                <div className="col-7 my-2 position-relative px-4">
                    <div className="row text-center mb-1">
                        <div className="col-6 ">
                            <div className="staff-card-data">{(staffData && staffData.total_leads) || 0}</div>
                            <div className="staff-card-label">Lead</div>
                        </div>
                        <div className="col-6">
                            <div className="staff-card-data">{(staffData && staffData.total_followUps) || 0}</div>
                            <div className="staff-card-label">Followups</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="staff-card-name">{user}</div>
                        <div className="staff-card-role">{role}</div>
                        {/* {auth.role === "company"&&<div className="agent-card-role text-primary mouse-pointer" onClick={()=>setNumberModal(true)}>Caller Id</div>} */}
                    </div>
                    {auth.role === "company" && <div className="row staff-card-btnholder me-1">
                        <div className="">
                            <button onClick={() => edit(data)} className="btn staff-card-btn px-2 mx-1 btn-outline-primary">
                                <Icon name="pencil alternate" />
                            </button>
                            <button onClick={() => setShowModal(true)} className="btn staff-card-btn mx-1 px-2 btn-primary">
                                <Icon name="trash alternate outline" />
                            </button>
                        </div>

                    </div>}
                </div>
                {auth.role === "agent" && <div className="row my-2">
                    <div className="col-md-12">
                        <button onClick={() => open(data)} className="btn col-12 btn-pill btn-outline-primary">
                            Assign Lead
                        </button>
                    </div>
                </div>}
            </div>
                :
                <div className="staff-card col-12 row py-1">
                    <div className="col-5 staff-card-img my-2">
                        <img src={img && MEDIA_URL + img} className="" alt="" />
                    </div>
                    <div className="col-7 my-auto position-relative px-4">

                        <div className="row">
                            <div className="staff-card-name">{user}</div>
                            <div className="staff-card-role">{role}</div>
                            {/* {auth.role === "company"&&<div className="agent-card-role text-primary mouse-pointer" onClick={()=>setNumberModal(true)}>Caller Id</div>} */}
                        </div>
                        {auth.role === "company" &&
                            <div className="row staff-card-btnholder me-1">
                                <div className="">
                                    <button onClick={() => edit(data)} className="btn staff-card-btn px-2 mx-1 btn-outline-primary">
                                        <Icon name="pencil alternate" />
                                    </button>
                                    <button onClick={() => setShowModal(true)} className="btn staff-card-btn mx-1 px-2 btn-primary">
                                        <Icon name="trash alternate outline" />
                                    </button>
                                </div>

                            </div>
                        }
                    </div>
                    <div className="row text-center mb-1 mx-auto">
                        <div className="col-4 ">
                                <div className="agent-card-data">{staffData.total_leads || 0}</div>
                                <div className="agent-card-label">Lead</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.leads_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{staffData.leads_rejected || 0}</div>
                                <div className="agent-card-label">Rejected</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.total_followUps || 0}</div>
                                <div className="agent-card-label">Followups</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{staffData.follow_ups_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{staffData.leads_closed || 0}</div>
                                <div className="agent-card-label">Closed</div>
                            </div>
                        </div>
                    {auth.role === "agent" &&

                        <div className="row my-2">
                            <div className="col-md-12">
                                <button onClick={() => open(data)} className="btn col-12 btn-pill btn-outline-primary">
                                    Assign Lead
                                </button>
                            </div>
                        </div>
                    }
                </div>}
            <Modal
                show={showModal}
                onHide={handleClose}
                centered={true}
                size="lg"
                contentClassName="agent-modal"
            >
                <Modal.Body className='p-5'>
                    <div className="">
                        <div className="row">
                            <div className="h3 fw-bolder">Delete Staff</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row my-4">
                                    <div className="col-md-12 px-5 fs-5">
                                        Are you sure you wan't to delete agent <b className="text-primary fs-4">{user}</b> ?

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row staff-btn-container p-2">
                            <div className="row col-md-6">
                                <div className="col-md-6 p-2">
                                    <button type='button' onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-md-6 p-2">
                                    <button type='button' onClick={(e) => handleSubmit(e)} className='btn btn-danger staff-btn col-12'>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={numberModal}
                onHide={handleNumberClose}
                centered={true}
                size="md"
                contentClassName=""
                >
                <Modal.Body>
                    <ExtensionForm setNumberModal={setNumberModal} userID={data?.fkUser} />
                    {/* <Form onSubmit={handleNumberSubmit}>
                    <div className='p-2'>
                        <h3>Agent Number</h3>
                        <div className="row my-3">
                            {agentNumber?
                                <div className="row mb-2 d-flex align-items-center">
                                    <h5 className="ps-5 text-success col-6">{agentNumber.agent_number}</h5>
                                    <h5 className="ps-5 text-success col-4">{agentNumber.extension}</h5>
                                    <div className="col-1">
                                        <span className="btn btn-danger" onClick={deleteAgentExtension}><MdDelete size="18px" /></span>
                                    </div>
                                </div>                                                
                                :
                                <h5 className="ms-5 text-warning">Number Not Assigned</h5>
                            }
                            <hr />
                            <div className="px-1 col-7">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className=''>Agent Number</Form.Label>
                                    <Form.Select
                                        className={"mouse-pointer type-dropdown "}
                                        disabled={agentNumber?true:false}
                                        size="md"
                                        onChange={(e)=>getExtList(e)}
                                        >
                                        <option className="menu" value=""></option>
                                        {extensionList && extensionList.map((data, index) =>
                                            <option className="menu" value={data?.agent_number}>{data?.agent_number}</option>
                                        )}       
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="px-1 col-5">
                                <Form.Group className='' controlId=''>
                                    <Form.Label className=''>Extension</Form.Label>
                                    <Form.Select
                                        className={"mouse-pointer type-dropdown "}
                                        disabled={(!agentNumber&&extList)?false:true}
                                        size="md"
                                        onChange={(e)=>setNewExtension(e.target.value)}
                                        >
                                        <option className="menu" value=""></option>
                                        {extList && extList.map((data, index) =>
                                            <option className="menu" value={data}>{data}</option>
                                        )}       
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="position-relative mb-5">
                        <div className='position-absolute end-0 row'>
                            <div onClick={handleNumberClose} className='col btn btn-danger mx-2'>Cancel</div>
                            <button onClick={(e)=>handleNumberSubmit(e)} className='col btn btn-primary' disabled={newExtension?false:true}>Submit</button>
                        </div>
                        </div>
                    </div>
                    </Form> */}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StaffCard