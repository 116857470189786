import './Staff.css'
import { Icon } from 'semantic-ui-react'
import StaffCard from './components/StaffCard'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import StaffAddForm from './components/StaffAddForm'
import add_img from "../../assets/images/icons/add_img.png"
import useAuth from '../../hooks/useAuth'
import { Navigate } from 'react-router-dom'
import useBaseServices from '../../services/baseServices'
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import AssignStaffLeadTable from './components/AssignStaffLeadTable'
import { MEDIA_URL } from '../../api/axios'


const Staff = () => {
    const [addModal, setAddModal] = useState(false)
    const [assignModal, setAssignModal] = useState(false)

    const [name, setName] = useState("")
    const [post, setPost] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")

    const [currentStaff, setCurrentStaff] = useState("")
    const [fkUser, setFkUser] = useState("")

    //lists
    const [staffList, setStaffList] = useState("")
    const [leadList, setLeadList] = useState("")

    const [imagePreview, setImagePreview] = useState(add_img)
    const [selected, setSelected] = useState("")

    const [edit, setEdit] = useState()

    const { auth } = useAuth()

    //services
    const { postStaffRegister } = useBaseServices()
    const { getCompanyStaffList, putCompanyStaffDetails } = useCompanyServices()
    const { getAgentStaffList, getStaffAssignLead } = useAgentServices()

    useEffect(() => {
        getStaffList()
    }, [])

    useEffect(() => {
        if (!selected) {
            setImagePreview(add_img)
            return
        }

        const objectUrl = URL.createObjectURL(selected)
        setImagePreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selected])



    const getStaffList = async (e) => {
        try {
            var response;
            if (auth.role === "company") {
                response = await getCompanyStaffList()
            }
            else if (auth.role === "agent") {
                response = await getAgentStaffList()
            }
            if (response?.success) {
                setStaffList(response?.data)
            }
        } catch (err) { console.log(err) }

    }

    const getLeadList = async (id, choice) => {
        try {
            const response = await getStaffAssignLead(currentStaff||id, choice)
            if (response.success) {
              // console.log(response?.data)
              setLeadList(response.data)
            }
          } catch (err) { console.log(err) }
    }

    const handleOpenAssignModal = (data) => {
        setAssignModal(true)
        setCurrentStaff(data.fkStaff.id || data.id)
        //console.log(data)
        setFkUser(data.fkStaff.fkUser || data.fkUser)
        getLeadList(data.fkStaff.id || data.id)
    }

    const handleImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelected(undefined)
            return
        }

        setSelected(e.target.files[0])
    }
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const data = new FormData();
            if (edit) {
                data.append("first_name", name)

            } else {
                data.append("name", name)
            }
            if (selected) {
                data.append("image", selected)
            }
            data.append("mobile", mobile)
            data.append("email", email)
            data.append("post", post)

            var response;
            if (edit) {
                response = await putCompanyStaffDetails(currentStaff, data)
            }
            else {
                response = await postStaffRegister(data)
            }
            if (response.success) {
                setAddModal(false);
                handleClear()
                getStaffList()
            }

        } catch (err) {

        }
    }
    const handleEdit = (data) => {
        setName(data.first_name)
        setEmail(data.email)
        setMobile(data.mobile)
        setPost(data.post)
        setImagePreview(MEDIA_URL + data.image)
        setEdit(true)
        setCurrentStaff(data.id)
        handleOpenAddModal("", true)
    }

    const handleClear = () => {
        setName("")
        setEmail("")
        setMobile("")
        setPost("")
        setSelected("")
        setImagePreview("")
    }

    const handleClose = () => setAddModal(false)

    const handleCloseAssignModal = () => {
        setAssignModal(false)
        getStaffList()
    }

    const handleOpenAddModal = (e, data) => {
        if (!data) {
            handleClear()
        }
        setAddModal(true)
    }

    return (
        auth.role === "company" || auth.role === "agent" ?
            <div className="ms-md-0 ms-4">
                <div className="row">
                    <div id="staff-header-btn" className="row">
                        {/* <div className="staff-cal btn button text-bg-primary me-2 px-1">
                        <Icon name="calendar alternate outline" size='large' />
                    </div> */}
                        {auth.role === "company" && <div onClick={() => setAddModal(true)} className="staff-cal ui circular button text-bg-primary">
                            <Icon name="add user" />
                            Add Agent
                        </div>}
                        <Modal
                            show={addModal}
                            onHide={handleClose}
                            centered={true}
                            size="lg"
                            contentClassName="staff-modal"
                        >
                            <Modal.Body className='p-5'>
                                <StaffAddForm
                                    {...{
                                        imagePreview, handleImage,
                                        selected, handleSubmit, handleClose,
                                        name, setName,
                                        post, setPost,
                                        mobile, setMobile,
                                        email, setEmail
                                    }} />
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={assignModal}
                            onHide={handleCloseAssignModal}
                            centered={true}
                            size="lg"
                            contentClassName="agent-modal"
                        >
                            <Modal.Body className='p-5'>
                                <AssignStaffLeadTable leadList={leadList} fkUser={fkUser} getLeadList={getLeadList} currentStaff={currentStaff} close={handleCloseAssignModal} title="Assign Leads" />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className="row">
                    {staffList && staffList.map((data, index) =>
                        <StaffCard key={index} edit={handleEdit} refresh={getStaffList} open={handleOpenAssignModal} user={data?.first_name || data?.fkStaff?.first_name || ""} img={data?.image || data?.fkStaff?.image || ""} role={data?.post || data?.fkStaff?.post || ""} data={data} />
                    )}

                </div>
            </div> :
            <Navigate to="/" />
    )
}

export default Staff