// import axios, { axiosPrivate } from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useAgentServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get dashboard data
    const getAgentDashboard = async () => {
        const response = await axiosPrivate.get("/agent/dashboard/")
        return response.data
    }

    //get staff list
    const getAgentStaffList = async () => {
        const response = await axiosPrivate.get("/agent/staff_list/")
        return response.data
    }

    //get staff details
    const getAgentStaffDetails = async (id) => {
        const response = await axiosPrivate.get("/agent/staff_details/" + id + "/")
        return response.data
    }

    //get lead list
    const getAssignedLeadList = async (type, data, multiple) => {
        if (multiple) {
            // here type = fromdate and data = todate
            const response = await axiosPrivate.get("/agent/lead_list/", {
                params: {
                    'from': type,
                    'to': data
                }
            })
            return response.data
        }
        else {
            // here type= parameter key and data = value
            const response = await axiosPrivate.get("/agent/lead_list/", {
                params: { [type]: data }
            })
            return response.data
        }
    }

    //get leads list to assign
    const getStaffAssignLead = async (id, choice) => {
        const response = await axiosPrivate.get("/agent/staff_assign_lead/" + id + "/", {
            params: { "choice": choice }
        })
        return response.data
    }

    //assign leads to staffs
    const postStaffAssignLead = async (id, data) => {
        const response = await axiosPrivate.post("/agent/staff_assign_lead/" + id + "/", data)
        return response.data
    }

    //returns
    return {
        getAgentDashboard,
        getAgentStaffList,
        getAgentStaffDetails,
        getAssignedLeadList,
        getStaffAssignLead,
        postStaffAssignLead,

    }
}
export default useAgentServices