import { useEffect } from "react"
import { useState } from "react"
import { Form} from "react-bootstrap"
import { RxUpdate } from "react-icons/rx"
import Swal from "sweetalert2"
import { MEDIA_URL } from "../../../api/axios"
import useCustomerServices from "../../../services/customerServices"
import useLeadServices from "../../../services/leadServices"

const LeadFieldList = ({field, id, refresh, edit, phase}) => {
    const [fieldContentList, setFieldContentList] = useState("")
    const [contentId, setContentId] = useState("")
    const [file, setFile] = useState("")

    useEffect(()=>{
        getFieldContentList()
    },[])

    const { putFieldContent, postFieldContent, getAllFieldContents } = useLeadServices()
    const { getCustomerFieldContents, postCustomerFieldContent } = useCustomerServices()

    const getFieldContentList = async () => {
        var response;
        if(phase==="customer"){
            response = await getCustomerFieldContents(id, field.name)
        }
        else{
            response = await getAllFieldContents(id, field.name)
        }
        if (response !== null) {
            // console.log(response?.data)
            setContentId(response?.data?.id||"")
            setFieldContentList(response?.data?.content||"")
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        // console.log(contentId)
        try{
            if(contentId!==""){
                const datas = new FormData()
                {(field.type==="File"||field.type==="Image")?
                datas.append("data", file):
                datas.append("data", fieldContentList)}
                // console.log(datas)
                const response = await putFieldContent(contentId, datas)
                // console.log(response?.data)
                if(response?.success){
                    refresh()
                    Swal.fire("","Content updated successfully","success")
                    getFieldContentList()
                }
                else{
                    refresh()
                    Swal.fire("","Content Updation failed","")
                }
            }
            else{
                const datas = new FormData()
                datas.append("name", field.name)
                {(field.type==="File"||field.type==="Image")?
                datas.append("data", file):
                datas.append("data", fieldContentList)}
                var response;
                if(phase==="customer"){
                    response = await postCustomerFieldContent(id, datas)
                }
                else{
                    response = await postFieldContent(id, datas)
                }
                
                // console.log(response?.data)
                if(response?.success){
                    Swal.fire("",response?.message,"success")
                    getFieldContentList()
                }
                else{
                    Swal.fire("",response?.message,"")
                }
            }
        }
        catch(err){
        }
    }

    const handleFile = (e) =>{
        setFieldContentList(e.target.files[0].name)
        setFile(e.target.files[0])
    }

    return (
        <tr className='row'>
            
            <td className="ps-md-5 pt-3 col-md-3 col-12">
                <Form.Label className='fw-bolder'>{field.name}</Form.Label>
            </td>
            {(field.type!=="File"&&field.type!=="Image")&&
                <td className="pe-md-4 pe-3 col-md-8 col-10">
                    {field.type==="Character"&&
                        <Form.Control
                            type="text"
                            className='py-2'
                            value={fieldContentList}
                            onChange={(e)=>setFieldContentList(e.target.value)}
                        />
                    }
                    {field.type==="Number"&&
                        <Form.Control
                            type="number"
                            className='py-2'
                            value={fieldContentList}
                            onChange={(e)=>setFieldContentList(e.target.value)}
                        />
                    }
                    {field.type==="Email"&&
                        <Form.Control
                            type="email"
                            className='py-2'
                            value={fieldContentList}
                            onChange={(e)=>setFieldContentList(e.target.value)}
                        />
                    }
                    
                    {field.type==="TextArea"&&
                        <Form.Control
                            as = "textarea"
                            className='py-2'
                            value={fieldContentList}
                            onChange={(e)=>setFieldContentList(e.target.value)}
                        />
                    }
                </td>
            }
            {(field.type==="File"||field.type==="Image")&&
                <td className=" col-md-5 col-12 pt-3">
                    <a href={MEDIA_URL+fieldContentList} className="text-dark" target="_blank" rel="noreferrer" hidden={fieldContentList?false:true}>
                        {(fieldContentList&&fieldContentList.split('/').reverse()[0])||"None"}
                    </a>
                </td>
            }
            {(field.type==="File"||field.type==="Image")&&
                <td className="pe-3 pe-md-4 col-md-3 col-10">
                    {field.type==="Image"&&
                        <Form.Control
                            type="file"
                            className='py-2'
                            onChange={(e)=>handleFile(e)}
                            accept='image/*'
                        />
                    }
                    {field.type==="File"&&
                        <Form.Control
                            type="file"
                            className='py-2'
                            onChange={(e)=>handleFile(e)}
                        />
                    }
                </td>
            }
            <td className="col-2 col-md-1 ps-0 ps-md-4">
                <div className='btn my-1 p-1 btn-success' onClick={(e)=>handleEdit(e)}><RxUpdate size="20px" /></div>
            </td>        
        </tr>
    )
}


export default LeadFieldList