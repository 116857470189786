import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal, Nav, Tab } from "react-bootstrap"
import Swal from "sweetalert2"
import useLeadServices from "../../../services/leadServices"
import CustomerAddForm from "./CustomerAddForm"
import CustomerProfile from "../../customer/components/CustomerProfile"
import LeadFieldList from "./LeadFieldList"
import CallLogs from "../../../components/calls/CallLogs"
import { FiPhoneCall } from "react-icons/fi"
import FollowUpLog from "../../folowups/components/FollowUpLog"

const LeadCard = ({ index, data, auth, updateLeads, getFieldTypeList, fieldTypeList }) => {
    const [descModal, setDescModal] = useState(false)
    const [showProfile, setShowProfile] = useState(false)

    const [key, setKey] = useState("tab1")

    const { putLeadStatusChange, getClick2Call } = useLeadServices()

    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [mail, setMail] = useState("")
    const [course, setCourse] = useState("")
    const [location, setLocation] = useState("")
    const [source, setSource] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    var fieldData = [];


    const loadData = () => {
        setId(data.id)
        setName(data.name)
        setMobile(data.mobile)
        setMail(data.email)
        setCourse(data.interest)
        setLocation(data.location)
        setStatus(data.status)
        setSource(data.source)
        setDescription(data.description)
    }
    useEffect(() => {
        loadData()
    }, [data])
    const handleCloseDesc = () => setDescModal(false);

    const handleSubmit = async (key, e) => {
        e && e.preventDefault()
        var datas = null
        // console.log(e.target.value)
        if (key === "status") {
            setStatus(e.target.value)
            datas = JSON.stringify({
                'status': e.target.value
            });
        }
        else if (key === "description") {
            datas = JSON.stringify({
                'description': description
            });
        }
        const response = await putLeadStatusChange(id, data = datas)
        if (response?.success) {
            handleCloseDesc()
            updateLeads()
        }
        else {
            Swal.fire("", response?.message, "error")
        }
    }

    const getDate = (data) => {
        return new Date(data).toString().slice(4, 15)
    }
    const handleClose = () => setShowProfile(false)

    const handleCall = async()=>{
        try{
            const response = await getClick2Call(data?.mobile)
            if (response?.success){
                console.log(response?.data)
                let timerInterval
                Swal.fire({
                  html: '<b>Calling <div class="loader"><span class="dot dot-1"></span><span class="dot dot-2"></span><span class="dot dot-3"></span></div> </b>',
                  timer: 2500,
                  timerProgressBar: false,
                  showConfirmButton: false,
                  
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
            }
            else{
              Swal.fire("Error!!!", response?.message, "error")
            }
        }catch(e){}
    }

    return (
        <div className="col-lg-12 me-5 ">
            <Modal
                size="lg"
                centered
                show={descModal}
                onHide={handleCloseDesc}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <Form onSubmit={(e) => handleSubmit("description", e)}>
                        <div className="row mb-3 my-3">
                            <div className="h3 fw-bold">Add Description</div>
                        </div>
                        <div className="row mb-3">
                            <Form.Control className="lead-desc-area p-4" as="textarea" placeholder="enter lead status description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="row mb-3">
                            <div className="float-end">
                                <button className="btn btn-primary btn-pill float-end col-4">Save</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {window.innerWidth > 768 ?
                <div className="lead-card col-12 row p-3 pe-4 me-3 ">
                    <div className="col-1 pe-0 py-1 my-2  lead-card-title">{index}</div>
                    <div className="col-1 my-2 lead-card-title p-1 px-0 overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{name}</div>
                    <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2">{mobile}</div>
                    <div className="col-1 my-1 lead-card-title p-1 pe-0 ps-5">
                        {/* <FiPhoneCall size="28px" className="btn call-onclick" onClick={handleCall} /> */}
                    </div>
                    <div className="col-3 my-2 lead-card-title p-1 ps-0 pe-0 overflow-auto">{mail}</div>
                    <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">{getDate(data?.created_at)}</div>
                    {/* <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">{getDate(data?.updated_at)}</div> */}
                    <div className="col-1 my-2 lead-card-title p-1 ps-2 overflow-auto">{source}</div>
                    <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-1 overflow-hidden">{course}</div>
                    <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2 overflow-hidden">{location}</div>
                    <div className="col-1 lead-card-title my-2 px-0">
                        <div className="">
                            <Form.Select
                                value={status}
                                onChange={(e) => (handleSubmit("status", e))}
                                className="status-dropdown"
                                size="sm"
                                disabled={(status === 'Closed' && auth.role !== 'company')? true : false}
                            >
                                <option className="menu" value="New">New</option>
                                <option className="menu" value="Open">Open</option>
                                <option className="menu" value="Closed">Closed</option>
                                <option className="menu" value="Paused">Paused</option>
                                <option className="menu" value="Rejected">Rejected</option>
                                <option className="menu" value="Unavailable">Unavailable</option>
                            </Form.Select>
                        </div>
                    </div>
                </div>
                :
                <div className="followup-card responsive col-12 p-3 pe-0 my-2">
                    <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>
                                    <div className=" overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{data?.name}</div>
                                
                                </td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td className="d-flex align-items-center">
                                    <div className="col-10 my-2">{mobile}</div>
                                    <div className="col-2">
                                        {/* <FiPhoneCall size="28px" className="btn call-onclick" onClick={handleCall} /> */}
                                    </div>
                                    
                                </td>
                            </tr>
                            <tr>
                                <th>E-mail</th>
                                <td className=" overflow-scroll">{mail}</td>
                            </tr>
                            <tr>
                                <th className="">Created On</th>
                                <td>{getDate(data?.created_at)}</td>
                            </tr>
                            <tr>
                                <th>Source</th>
                                {/* <td>{getDate(data?.updated_at)}</td> */}
                                <td>{source}</td>
                            </tr>
                            <tr>
                                <th>Interested</th>
                                <td>{course}</td>
                            </tr>
                            <tr>
                                <th>Location</th>
                                <td>{location}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>
                                    <div className="">
                                        <Form.Select
                                            value={status}
                                            onChange={(e) => (handleSubmit("status", e))}
                                            className="status-dropdown"
                                            disabled={(status === 'Closed' && auth.role !== 'company')? true : false}
                                            size="sm"
                                        >
                                            <option className="menu" value="New">New</option>
                                            <option className="menu" value="Open">Open</option>
                                            <option className="menu" value="Closed">Closed</option>
                                            <option className="menu" value="Paused">Paused</option>
                                            <option className="menu" value="Rejected">Rejected</option>
                                            <option className="menu" value="Unavailable">Unavailable</option>
                                        </Form.Select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            <Modal
                show={showProfile}
                onHide={handleClose}
                centered={true}
                size="xl"
                contentClassName="lead-modal px-md-4 py-2 p-4"
            >
                <Modal.Body className=''>
                    <div className='card document-card row'>
                        <CustomerProfile data={data} refresh={updateLeads} handleProfileClose={handleClose} type={"Lead"} />
                        <div className='col-12'>
                            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                                <div className="row">
                                    <div className="col-12">
                                        <Nav variant="pills" className="flex-md-row flex-column">
                                            <Nav.Item className='pt-2 px-md-3'>
                                                <Nav.Link className='py-3  px-md-4 nav-panes' eventKey="tab1">Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='pt-2'>
                                                <Nav.Link className='py-3 nav-panes' eventKey="tab2">Follow Ups</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item className='pt-2 px-md-3'>
                                                <Nav.Link className='py-3 px-md-3 nav-panes' eventKey="tab3">Call Logs</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                    <div className="col-12 pt-3 py-0">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab1" className=" pt-4">
                                                <div className='px-2 field-card'>
                                                    <table className='table table-striped'>
                                                        <tbody className=''>
                                                            {fieldTypeList && fieldTypeList.map(
                                                                (field, index) => {
                                                                    return (
                                                                        <LeadFieldList key={index} field={field} id={data.id} refresh={getFieldTypeList} edit={fieldData} />
                                                                    )
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2">
                                                <FollowUpLog id={data.id} />
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="tab3">
                                                <CallLogs type={'lead'} content={data} />
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                        <div className='col-12 row py-3'>
                            <div className='col-7 col-md-9'></div>
                            <div onClick={handleClose} className='btn btn-primary btn-pill col-4 col-md-2'>Done</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LeadCard