import { Icon } from "semantic-ui-react"
import { Form, Modal } from 'react-bootstrap'
import useAuth from "../../../hooks/useAuth"
import useCompanyServices from "../../../services/companyServices"
import { useState } from "react"
import useBaseServices from "../../../services/baseServices"
import Swal from "sweetalert2"
import { MdDelete } from "react-icons/md"
import ExtensionForm from "../../../components/forms/ExtensionForm"

const AgentCard = ({ img, user, role, data, open, edit, assignStaff, refresh }) => {
    const { auth } = useAuth()
    const [numberModal, setNumberModal] = useState(false)

    const { deleteAgentDetailsToday } = useCompanyServices()

    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false)


    const handleSubmit = async (e) => {
        try {
            const response = await deleteAgentDetailsToday(auth.role === "company" ? data.id : "")
            if (response?.success) {
                refresh()
            }
        } catch (err) {

        }
    }

    const handleNumberClose = ()=>{
        setNumberModal(false)
    }

    return (
        <div className="col-lg-4 col-md-6 my-3">
            <div className={
                // isHover?
                // "agent-card scale-2"
                // :
                "agent-card"
            }>
                {window.innerWidth > 768 ?
                    <div className=" col-12 row py-0">
                        <div 
                        // onMouseLeave={() => setIsHover(false)} 
                        className="col-md-5 agent-card-img my-2 px-4 py-1">
                            <img
                            //  onMouseEnter={() => setIsHover(true)} 
                             src={img} className="" alt="" />
                        </div>
                        <div className="col-md-7 my-2 position-relative px-4">
                            <div className="row text-center mb-1">
                                <div className="col-md-6 ">
                                    <div className="agent-card-data">{data.report.total_leads || 0}</div>
                                    <div className="agent-card-label">Lead</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="agent-card-data">{data.report.total_followUps || 0}</div>
                                    <div className="agent-card-label">Followups</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="agent-card-name">{user}</div>
                                <div className="agent-card-role">{role}</div>
                                {/* <div className="agent-card-role text-primary mouse-pointer" onClick={()=>setNumberModal(true)}>Caller Id</div> */}
                            </div>

                        </div>
                    </div>
                    :
                    <div className=" col-12 row py-0">
                        <div 
                        // onMouseLeave={() => setIsHover(false)} 
                        className="col-md-5 col-5 agent-card-img my-2 px-4 py-1">
                            <img
                            //  onMouseEnter={() => setIsHover(true)}
                              src={img} className="" alt="" />
                        </div>
                        <div className="col-md-7 col-7 my-2 position-relative px-4 my-auto">

                            <div className="row">
                                <div className="agent-card-name">{user}</div>
                                <div className="agent-card-role">{role}</div>
                                {/* <div className="agent-card-role text-primary mouse-pointer" onClick={()=>setNumberModal(true)}>Caller Id</div> */}
                            </div>

                        </div>
                        <div className="row text-center mb-1 mx-auto">
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.total_leads || 0}</div>
                                <div className="agent-card-label">Lead</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.leads_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.leads_rejected || 0}</div>
                                <div className="agent-card-label">Rejected</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.total_followUps || 0}</div>
                                <div className="agent-card-label">Followups</div>
                            </div>
                            <div className="col-4 ">
                                <div className="agent-card-data">{data.report.follow_ups_completed || 0}</div>
                                <div className="agent-card-label">Completed</div>
                            </div>
                            <div className="col-4">
                                <div className="agent-card-data">{data.report.leads_closed || 0}</div>
                                <div className="agent-card-label">Closed</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row col-12 mx-1 py-1 pb-3">
                    <div className="col-md-4 px-1 pe-2 pe-md-1 py-2 py-md-0">
                        <button onClick={() => assignStaff(data)} className="col-12 p-2 px-3 btn agent-card-btn-sm btn-primary">Team Assign</button>
                    </div>
                    <div className="col-md-4 px-1 pe-2 pe-md-1 py-2 py-md-0">
                        <button onClick={() => open(data)} value={data.id} className="col-12 btn p-2 px-3 agent-card-btn-sm btn-outline-primary">Assign Lead</button>
                    </div>
                    <div className="col-md-4 px-md-3 px-0 py-2 py-md-0">
                        <div className="row m-0 px-0">
                            {window.innerWidth > 768 ?
                                <>
                                    <div className="col-md-6 px-3">
                                        <button onClick={() => edit(data)} className="btn agent-card-btn-sm px-2 p-2 btn-outline-primary">
                                            <Icon name="pencil alternate" />
                                        </button>
                                    </div>
                                    <div className="col-md-6 px-3">
                                        <button onClick={() => setShowModal(true)} className="btn agent-card-btn-sm p-2 px-2 btn-primary">
                                            <Icon name="trash alternate outline" />
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-6 ps-1 px-1">
                                        <button onClick={() => edit(data)} className="btn agent-card-btn-sm col-12 px-0 p-2 btn-outline-primary">
                                            <Icon name="pencil alternate" />
                                        </button>
                                    </div>
                                    <div className="col-6 pe-2 px-0">
                                        <button onClick={() => setShowModal(true)} className="btn agent-card-btn-sm col-12 p-2 px-0 btn-primary">
                                            <Icon name="trash alternate outline" />
                                        </button>
                                    </div></>}
                            <Modal
                                show={showModal}
                                onHide={handleClose}
                                centered={true}
                                size="lg"
                                contentClassName="agent-modal"
                            >
                                <Modal.Body className='p-5'>
                                    <div className="">
                                        <div className="row">
                                            <div className="h3 fw-bolder">Delete Staff</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row my-4">
                                                    <div className="col-md-12 px-5 fs-5">
                                                        Are you sure you wan't to delete agent <b className="text-primary fs-4">{user}</b> ?

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row staff-btn-container p-2">
                                            <div className="row col-md-6">
                                                <div className="col-6 p-2">
                                                    <button type='button' onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                                                </div>
                                                <div className="col-6 p-2">
                                                    <button type='button' onClick={(e) => handleSubmit(e)} className='btn btn-danger staff-btn col-12'>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal
                                show={numberModal}
                                onHide={handleNumberClose}
                                centered={true}
                                size="md"
                                contentClassName=""
                                >
                                <Modal.Body>
                                    <ExtensionForm setNumberModal={setNumberModal} userID={data?.fkUser} />
                                    {/* <Form onSubmit={handleNumberSubmit}>
                                    <div className='p-2'>
                                        <h3>Agent Number</h3>
                                        <div className="row my-3">
                                            {agentNumber?
                                                <div className="row mb-2 d-flex align-items-center">
                                                    <h5 className="ps-5 text-success col-5">{agentNumber.agent_number}</h5>
                                                    <h5 className="ps-5 text-success col-5">{agentNumber.extension}</h5>
                                                    <div className="col-1">
                                                        <span className="btn btn-danger" onClick={deleteAgentExtension}><MdDelete size="18px" /></span>
                                                    </div>
                                                </div>                                                
                                                :
                                                <h5 className="ms-5 text-warning">Number Not Assigned</h5>
                                            }
                                            <hr />
                                            <div className="px-1 col-12">
                                                <Form.Group className='' controlId=''>
                                                    <Form.Label className=''>Agent Number</Form.Label>
                                                    <Form.Select
                                                        className={"mouse-pointer type-dropdown "}
                                                        disabled={agentNumber?true:false}
                                                        size="md"
                                                        onChange={(e)=>setNewNumber(e.target.value)}
                                                        >
                                                        {extensionList && extensionList.map((data, index) =>
                                                            <option className="menu" value={data?.agent_number}>{data?.agent_number}</option>
                                                        )}       
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="position-relative mb-5">
                                        <div className='position-absolute end-0 row'>
                                            <div onClick={handleNumberClose} className='col btn btn-danger mx-2'>Cancel</div>
                                            <button onClick={(e)=>handleNumberSubmit(e)} className='col btn btn-primary'>Submit</button>
                                        </div>
                                        </div>
                                    </div>
                                    </Form> */}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentCard