
import user from "../../../assets/images/logos/user.png"
import user_success from "../../../assets/images/logos/user_success.png"
import graph from "../../../assets/images/logos/graph.png"
import graph_success from "../../../assets/images/logos/graph_success.png"
import DashboardCard from "./DashboardCard"

import useAuth from "../../../hooks/useAuth"
import LeadUplodForm from "./LeadUplodForm"
import { Icon } from "semantic-ui-react"
import { Modal } from "react-bootstrap"
import { Bar, Line, PolarArea, Doughnut } from "react-chartjs-2"
import { Filler } from "chart.js";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';




const DashboardCompany = (props) => {

  ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend,Filler);

  const { lineData, lineOptions, barData, barOptions, pieData, pieOptions , cardData, show, setShow} = props
  const { auth } = useAuth()
  return (
    <div className="ms-md-0 ms-4">
      {/* {auth.role === 'company'?
      <div className="row">
        <div id="leadupload" className="row">
          <div onClick={handleUploadOpen} id="addLead" className="ui circular button text-bg-primary">
            <Icon name="cloud upload" />
            Lead Upload
          </div>
          <Modal
            show={uploadModal}
            onHide={handleUploadClose}
            centered
            size="lg"
            contentClassName="upload-modal"
          >
            <Modal.Body className="p-4">
              <LeadUplodForm {...{ handleFile, imagePreview, handleSubmit, handleUploadClose, previewText }} />
            </Modal.Body>
          </Modal>
        </div>
      </div>:<></>
      } */}
      <div className="row mt-3">
        <DashboardCard classes="company" img={graph} title="Total Lead" count={cardData&&cardData['total_leads']} />
        <DashboardCard classes="company" img={graph_success} title="Lead Completed" count={cardData&&cardData['leads_completed']} />
        <DashboardCard classes="company" img={user} title="Total Followups" count={cardData&&cardData['total_followups']} />
        <DashboardCard classes="company" img={user_success} title="Followups Completed" count={cardData&&cardData['followups_completed']} />

      </div>
      <div className="row mt-5">
        <div className=" col-md-6 p-4">
          <div className="chart-card p-3">
            <div className="row mb-3 ">
              <div className="h5 fw-bolder">Daily Lead</div>
            </div>
            <div className="row">
              <div className="col-md-2 my-auto">
                <div className="my-auto text-center">
                  <div className="dashboard-card-count company">
                    {cardData&&cardData['total_leads']}
                  </div>
                  <div className=" dashboard-chart-sub">
                    Today Total Leads
                  </div>

                </div>
              </div>
              <div className="col-md-10 ">
                <Line data={lineData} options={lineOptions} />

              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-6 p-4">
          <div className="chart-card p-3">
            <div className="row mb-3 ">
              <div className="h5 fw-bolder">Monthly Lead</div>
            </div>
            <div className="row">
              <div className="col-md-1 align-items-center h-100 pt-5 mt-5" onClick={()=>setShow(false)}>
                <Icon name="angle left" size="big" hidden={!show}/>
              </div>
              <div className="col-md-10 mx-auto">
                <Bar data={barData} options={barOptions} />
              </div>
              <div className="col-md-1 align-items-center h-100 pt-5 mt-5 pe-4" onClick={()=>setShow(true)}>
                <Icon name="angle right" size="big" hidden={show}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
                <div className=" col-md-7 p-4">
                    <div className="chart-card p-3">
                        <div className="row mb-3 ">
                            <div className="h5 fw-bolder">Call Lead Chart</div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 my-auto">
                                <div className="">
                                    <table className="m-auto pie-chart-text-container">
                                      <tbody>
                                        <tr>
                                          <td><button className="button ui px-3 py-2 chart-badge1"></button></td>
                                          <td className="ps-3 chart-badge-text">New Lead</td>
                                        </tr>
                                        <tr>
                                          <td><button className="button ui px-3 py-2 chart-badge2"></button></td>
                                          <td className="ps-3 chart-badge-text">Open</td>
                                        </tr>
                                        <tr>
                                          <td><button className="button ui px-3 py-2 chart-badge3"></button></td>
                                          <td className="ps-3 chart-badge-text">Rejected</td>
                                        </tr>
                                        <tr>
                                          <td><button className="button ui px-3 py-2 chart-badge4"></button></td>
                                          <td className="ps-3 chart-badge-text">Closed</td>
                                        </tr>
                                        <tr>
                                          <td><button className="button ui px-3 py-2 chart-badge5"></button></td>
                                          <td className="ps-3 chart-badge-text">Not Responded</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <Doughnut data={pieData} options={pieOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className=" col-md-5 p-4">
                    <div className="chart-card p-3">
                        <div className="row mb-3 ">
                            <div className="h5 fw-bolder">Monthly Lead</div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 mx-auto">

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
    </div>
  )
}

export default DashboardCompany