import React, { useEffect, useState } from 'react'
import { Checkbox, Dropdown, Icon
    // , Search
 } from 'semantic-ui-react'
import useCompanyServices from '../../../services/companyServices'

const AgentStaffTable = (props) => {

    const [selected, setSelected] = useState('all')
    const { staffList, currentAgent,
        // fkUser,
         getStaffList } = props

    const { postStaffAssignAgent } = useCompanyServices()

    const handleAssignStaff = async (staff_id) => {
        try {
            const data = JSON.stringify({
                staff_id: staff_id,
            })
            const response = await postStaffAssignAgent(currentAgent, data)
            if (response.success) {

                return true;
            }
        } catch (err) { console.log(err) }
    }

    const options = [
        { key: 1, text: 'All', value: "all" },
        { key: 2, text: 'Assign ', value: "assigned" },
        { key: 3, text: 'Unassign', value: "unassigned" },
    ]
    // const handleSearch = () => {

    // }

    const handleSelect = (event, data) => {
        setSelected(data.value)
        if (data.value === 'all') {
            getStaffList(null,null)
        } else if (data.value === 'assigned'){
            getStaffList(null,data.value)
        } else if (data.value === 'unassigned') {
            getStaffList(null,data.value)
        }
    }
    
    return (
        <div className='position-relative'>
            <div className="asign-lead-close">
                <button className='btn' onClick={props.close} ><Icon name="close" size="large" /></button>
            </div>
            <div className="row">
                <div className="h3 fw-bolder col-9">{props.title}</div>
                {/* <div className="col-md-2"> */}
                    {/* <Search
                        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                        placeholder="Search Here..."
                        // onResultSelect={}
                        size='small'
                        onSearchChange={handleSearch}

                    /> */}
                {/* </div> */}
                <div className="col-md-2">
                    <Dropdown
                        selection
                        fluid={true}
                        value={selected}
                        options={options}
                        onChange={handleSelect} />
                </div>
            </div>

            <div className="row my-3">
                <div className="assign-table-container p-4">
                    <div>
                        <div className="row py-2 assign-table-header">
                            <div className="col-2">Sl.No</div>
                            <div className="col-4">Name</div>
                            <div className="col-4">Phone Number</div>
                            {/* <div className="col-3">Interested</div> */}
                            <div className="col-2">Assign</div>
                        </div>
                    </div>
                    <div className="my-2 assign-table-body">
                        {staffList && staffList.map((data, index) => <StaffItem handleAssignStaff={handleAssignStaff} currentAgent={currentAgent} index={index} key={index} data={data} selected={selected} />)}
                        {/* {[1, 2, 3, 4, 5, 6].map((item) =>  */}
                        {/* <div className="row py-2 assign-table-content  my-1">
                            <div className="col-2">1</div>
                            <div className="col-3">David</div>
                            <div className="col-3">9988776655</div>
                            <div className="col-3">Python</div>
                            <div className="col-1">
                                <Checkbox />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


const StaffItem = ({ data, index, currentAgent, handleAssignStaff, selected }) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        setChecked(data.is_assigned)
    }, [data])
    const handleAssign = (value) => {
        const assign = handleAssignStaff(data.id)
        if(assign) {
          setChecked(value)  
        }
    }

    const handleCheck = (e) => {
        // setChecked()
        handleAssign(!checked)
    }
    // console.log(data)
    return (
        <div className="row py-2 assign-table-content my-1">
            <div className="col-2">{index+1}</div>
            <div className="col-4">{data.first_name}</div>
            <div className="col-4">{data.mobile}</div>
            {/* <div className="col-3">{data.interest}</div> */}
            <div className="col-2">
                <Checkbox toggle checked={checked} onChange={handleCheck} disabled={selected==='assigned'||!checked?false:true}/>
            </div>
        </div>
    )
}
export default AgentStaffTable