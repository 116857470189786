import { Link, useLocation } from "react-router-dom"
import { Icon, Popup } from "semantic-ui-react"
import "./Sidebar.css"
import dashboard_icon from "../../assets/images/icons/dashboard_icon.png"
import task_icon from "../../assets/images/icons/task_icon.png"
import usercheck_icon from "../../assets/images/icons/usercheck_icon.png"
import customer_icon from "../../assets/images/icons/customer_icon.png"
import call_icon from '../../assets/images/icons/call_log.png'
import company_logo from '../../assets/images/imgs/Logo.jpg';


const Sidebar = ({ isOpen, onClose, auth }) => {
    const location = useLocation()
    var companyLinks
    if (auth.role === "company") {
        companyLinks = [
            { to: "/", label: "Dashboard", icon: "grid layout" },
            { to: "/agents", label: "Sub Admin", icon: "users" },
            { to: "/staff", label: "Agent", icon: "users" },
            { to: "/leadlist", label: "Lead List", icon: "file alternate" },
            { to: "/followups", label: "Followups", icon: "file alternate" },
            { to: "/customer", label: "Customer", icon: "users" },
            { to: "/reports", label: "Reports", icon: "file alternate" },
            // { to: "/calllog", label: "Call Logs", icon: "file alternate" },
        ]
    } else if (auth.role === "agent") {
        companyLinks = [
            { to: "/", label: "Dashboard", icon: "grid layout" },
            // { to: "/agents", label: "Agents", icon: "users" },
            { to: "/staff", label: "Agent", icon: "users" },
            { to: "/leadlist", label: "Lead List", icon: "file alternate" },
            { to: "/followups", label: "Followups", icon: "file alternate" },
            { to: "/customer", label: "Customer", icon: "users" },
            { to: "/reports", label: "Reports", icon: "file alternate" },
            // { to: "/calllog", label: "Call Logs", icon: "file alternate" },            
        ]
    }
    const staffLinks = [
        { to: "/", label: "Dashboard", icon: dashboard_icon },
        { to: "/leadlist", label: "Lead List", icon: task_icon },
        { to: "/followups", label: "Followup List", icon: usercheck_icon },
        { to: "/customer", label: "Customer", icon: customer_icon },
        // { to: "/calllog", label: "Call Logs", icon: call_icon },

    ]
    return (
        auth?.role === "company" || auth?.role === "agent" ?
            <div className={`sidebar mt-3 company ${isOpen ? 'open ms-0 ms-md-3' : ''}`}>
                <SidebarCompany onClose={onClose} links={companyLinks} location={location} />
            </div>
            :
            <div className={`sidebar mt-3 agent ${isOpen ? 'open ms-3' : ''}`}>
                <SidebarAgent onClose={onClose} links={staffLinks} location={location} />
            </div>
    )
}

const SidebarCompany = ({ links, location, onClose }) => {
    return (
        <div className='position-relative p-1'>
            {/* {window.innerWidth < 768 &&<div className="position-absolute staff-toggle">
                <span onClick={onClose} className=""><Icon name="close" size="large" /></span>
            </div>} */}

            <div className='h4 mt-5 sidebar-header'>CRM company
            {/* <span onClick={onClose} className="float-end"><Icon name="arrow left" size="large" /></span> */}
            </div>
            <div className="link-container mt-5">
                <ul className="sidebar-items">
                    {links.map((link, index) =>
                        <li key={index} className="">
                            <Link onClick={window.innerWidth < 768 && onClose} className={location.pathname === link.to ? "py-2 active-company company" : "compnay py-2"} to={link.to} aria-expanded="false">

                                <Icon name={link.icon} />
                                <span className=" fs-5">{link.label}</span>
                            </Link>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
}
const SidebarAgent = ({ links, location, onClose }) => {
    return (
        <div className='position-relative p-1'>
            {/* {window.innerWidth < 768 &&<div className="position-absolute staff-toggle">
                <span onClick={onClose} className=""><Icon name="close" size="large" /></span>
            </div>} */}

            <div className='mt-3 sidebar-header col-12'>
                <img src={company_logo} className="sidebar-agent-logo col-12" alt="" />
            </div>
            <div className="link-container mt-1">
                <ul className="sidebar-items p-3">
                    {links.map((link, index) =>
                        <li key={index} className="px-2">
                            <Link onClick={window.innerWidth < 768 && onClose} className={" agent py-2"} to={link.to} aria-expanded="false">
                                
                                <Popup
                                    trigger={
                                        <div className={` btn col-12 sidebar-agent-links my-1 ${location.pathname === link.to ? 'active-agent' : ""}`}>
                                            <img src={link.icon} className="w-100 " alt="" />
                                        </div>}
                                    content={link.label}
                                    position='right'
                                    // position='bottom left'
                                    style={{
                                        "backgroundColor":"#1e4eca",
                                        "borderColor":"#1e4eca","color":"#ffffff",
                                        "borderRadius":"0.5rem","padding":"0.2rem 0.5rem", "marginTop":"0rem"}}
                                    size="small"
                                    basic
                                />
                                    
                                
                            </Link>
                        </li>)}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar